/**
 * --------------------------------------------------------------------------
 * Custom Tooltips for Chart.js (v2.0.0-alpha.0): index.umd.js
 * Licensed under MIT (https://github.com/@coreui/coreui-chartjs/LICENSE)
 * --------------------------------------------------------------------------
 */

import CustomTooltips from './src/custom-tooltips'

export default {
  CustomTooltips
}
